import React, { useState } from "react";
import { AppProps } from "next/app";

import "../styles/index.css";
import { ErrorContext } from "../context/ErrorContext";
import { CustomError } from "../utils/errors";
import ErrorModal from "../components/ErrorModal/ErrorModal";
import ModalPortal from "../components/Portal/ModalPortal";

function CustomApp({ Component, pageProps }: AppProps) {
  const [currentError, setCurrentError] = useState<CustomError>();
  return (
    <ErrorContext.Provider
      value={{
        reportError: async (error) => {
          setCurrentError(error);
        },
        currentError: currentError,
      }}
    >
      <Component {...pageProps} />
      {currentError && (
        <ModalPortal>
          <ErrorModal
            title={currentError.title}
            message={currentError.message}
            code={currentError.code}
            onClose={() => setCurrentError(undefined)}
          />
        </ModalPortal>
      )}
    </ErrorContext.Provider>
  );
}

export default CustomApp;
