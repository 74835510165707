import React from "react";
import { CustomError } from "../utils/errors";

type ErrorContextInterface = {
  reportError: (error: CustomError) => Promise<void>;
  currentError?: CustomError;
};

export const ErrorContext = React.createContext<ErrorContextInterface>({
  reportError: async () => {},
  currentError: undefined,
});

export const useErrorReporting = (): ErrorContextInterface =>
  React.useContext(ErrorContext);
