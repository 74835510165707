import React from "react";
import CloseIcon from "../../assets/ic_close_circle.svg";

export type ErrorModal = {
  title: string;
  message: string;
  code: string;
  onClose: () => void;
};

const ErrorModal = ({ title, message, code, onClose }) => {
  return (
    <div className="bg-modal rounded w-11/12 md:w-4/5 lg:w-1/2 top-1/2 left-1/2 text-white">
      <div
        className="flex justify-end pr-4 pt-4 -pb-8 cursor-pointer"
        onClick={onClose}
      >
        <CloseIcon />
      </div>
      <div className="flex flex-col items-center justify-center space-y-4 px-16 pt-6 pb-16">
        <div className="text-head text-white font-bold text-center">
          {title}
        </div>
        <div className="text-medium text-white text-opacity-60 text-center">
          {message}
        </div>
        <p>Error Code: {code}</p>
      </div>
    </div>
  );
};

export default ErrorModal;
