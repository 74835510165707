import { useRef, useState, useEffect } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: React.ReactNode;
  selector: string;
};

const Portal: React.FunctionComponent<PortalProps> = ({
  children,
  selector,
}) => {
  const ref = useRef<Element | undefined>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    ref.current = document.querySelector(selector) ?? undefined;
    setMounted(true);
  }, [selector]);

  if (mounted && ref.current) {
    return createPortal(children, ref.current);
  }

  return null;
};

export default Portal;
