import React from "react";
import Portal from "./Portal";

const ModalPortal: React.FunctionComponent = ({ children }) => (
  <Portal selector="body">
    <div className="fixed w-full h-full top-0 left-0">
      <div className="w-full h-full flex justify-center items-center overflow-hidden backdrop-blur z-20">
        {children}
      </div>
    </div>
  </Portal>
);

export default ModalPortal;
